import { useReducer, useState, useEffect } from 'react'
import './sign-up.scss';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { sendEmail } from '../../helpers/mailjet';

export default function SignUp() {

    const [success, setScuess] = useState(false);
    const [err, setErr] = useState(false);

    const initialState = {
        vorname: '',
        nachname: '',
        email: '',
        plz: '',
        newsletter: false,
        jahrgang: '',
        newsletter_bixs: false

    }

    const [reducerState, dispatch] = useReducer(reducer, initialState);
    const navigate = useNavigate();

    function reducer(state, action) {
        switch (action.type) {
            case 'UPDATE':
                return {
                    ...state, [action.key]: action.value,
                }
            case 'REPLACE':
                return action.payload;
            default:
                return state
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (reducerState.firstname !== '' && reducerState.lastname !== '' && reducerState.email !== '' && reducerState.plz !== '' && reducerState.jahrgang !== '') {
            setErr(false);
            postData();
        } else {
            setErr('Bitte alle Felder ausfüllen.');
        }



    }


    useEffect(() => {
        if (success && localStorage.getItem('type') !== 'ckw') {

            const redirectTimer = setTimeout(() => {
                navigate('/'); // Replace '/another-page' with the desired URL
                window.location.reload();
            }, 5000); // 5000 milliseconds = 5 seconds

            return () => {
                clearTimeout(redirectTimer); // Clear the timer when the component unmounts
            };
        }

    }, [success]);


    const postData = async () => {

        sendEmail(reducerState.email, reducerState.vorname, reducerState.nachname);

        const collection = 'wettbewerb';
        const body = { email: reducerState.email, firstname: reducerState.vorname, lastname: reducerState.nachname, plz: reducerState.plz, newsletter: reducerState.newsletter, jahrgang: reducerState.jahrgang, newsletter_bixs: reducerState.newsletter_bixs, event: localStorage.getItem('event'), result: localStorage.getItem('result') }

        await axios.post("https://delta12.cc/items/" + collection, body)
            .then((res) => {
                setScuess(true);

            })
            .catch((err) => {
                console.log(err);
                setErr(err);
            });
        localStorage.removeItem('result');
    };



    return (<div id="pop-up">

        <div className="pop-up-content">
            <div className="pop-up__text">



                {!success ?
                    <div>
                        <h1>Wettbewerb</h1>
                        <div>
                            <div>Hier kannst du deine Kontaktdaten eingeben und du nimmst an der Vorlesung teil.</div>
                            <form onSubmit={e => { handleSubmit(e) }}>

                                <div className="columns-2">
                                    <div className="input-wrapper">
                                        <input
                                            type="text"
                                            className="login input"
                                            placeholder="Vorname"
                                            value={reducerState.vorname}
                                            onChange={(e) =>
                                                dispatch({
                                                    type: 'UPDATE',
                                                    value: e.target.value,
                                                    key: 'vorname',
                                                })}
                                        />
                                    </div>
                                    <div className="input-wrapper">

                                        <input
                                            type="text"
                                            className="login input"
                                            placeholder="Nachname"
                                            value={reducerState.nachname}
                                            onChange={(e) =>
                                                dispatch({
                                                    type: 'UPDATE',
                                                    value: e.target.value,
                                                    key: 'nachname',
                                                })}
                                        />
                                    </div>
                                </div>


                                <div className="input-wrapper">

                                    <input
                                        type="text"
                                        className="login input"
                                        placeholder="Email"
                                        value={reducerState.email}

                                        onChange={(e) =>
                                            dispatch({
                                                type: 'UPDATE',
                                                value: e.target.value,
                                                key: 'email',
                                            })}
                                    /></div>

                                <div className="input-wrapper">

                                    <input
                                        type="text"

                                        className="login input"
                                        placeholder="Postleitzahl"
                                        value={reducerState.plz}

                                        onChange={(e) =>
                                            dispatch({
                                                type: 'UPDATE',
                                                value: e.target.value,
                                                key: 'plz',
                                            })}
                                    /></div>

                                <div className="input-wrapper">

                                    <input
                                        type="text"

                                        className="login input"
                                        placeholder="Jahrgang"
                                        value={reducerState.jahrgang}

                                        onChange={(e) =>
                                            dispatch({
                                                type: 'UPDATE',
                                                value: e.target.value,
                                                key: 'jahrgang',
                                            })}
                                    /></div>
                                <div className="input-wrapper input-wrapper--checkbox">
                                    <input type="checkbox" id="newsletter" name="newsletter" value="newsletter" checked={reducerState.newsletter} onChange={(e) =>
                                        dispatch({
                                            type: 'UPDATE',
                                            value: !reducerState.newsletter,
                                            key: 'newsletter',
                                        })}></input>
                                    <label htmlFor="newsletter"> Ich möchte weitere Infos zur Bikegenossenschaft Zentralschweiz erhalten.</label></div>
                                <div className="input-wrapper input-wrapper--checkbox">
                                    <input type="checkbox" id="newsletter_bixs" name="newsletter_bixs" value="newsletter_bixs" checked={reducerState.newsletter_bixs} onChange={(e) =>
                                        dispatch({
                                            type: 'UPDATE',
                                            value: !reducerState.newsletter_bixs,
                                            key: 'newsletter_bixs',
                                        })}></input>
                                    <label htmlFor="newsletter_bixs"> Ich möchte den Newsletter von BiXS abonnieren.</label></div>
                                {err ? <div className="error-notice">{err}</div> : null}
                                <input
                                    className='button'
                                    type='submit'
                                    value='abschicken'
                                />
                                <div className="datenschutz">Mit der Teilnahme an diesem Wettbewerb akzeptierst du unsere <a href="https://bikegenoss.ch/datenschutz/" target="_blank" >Datenschutzbestimmungen</a>.</div>
                            </form>
                        </div>
                    </div>



                    : <div>
                        <h1>Viel Glück!</h1>
                        <div>
                            Danke für deine Teilnahme am Wettbewerb. Die Verlosung findet Ende Saison statt.
                        </div>

                    </div>}

            </div>

        </div >
    </div >);
}