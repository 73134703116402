import logo from './logo.svg';
import './App.css';
import { BrowserRouter } from 'react-router-dom';

import Map from './containers/map/Map';
import BottomBanner from './containers/bottom-banner/BottomBanner';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Map />

      </BrowserRouter>

    </div>
  );
}

export default App;
