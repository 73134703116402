import axios from "axios";

const sendEmail = async (email, firstname, lastname) => {



    const url = '/api/index.php';

    const data = {
        email: email,
        firstname: firstname,
        lastname: lastname,
    };

    try {
        const response = await axios.post(url, data, { headers: { 'Content-Type': 'application/json' } });
        console.log(response.data); // Handle the response data
    } catch (error) {
        console.error(error); // Handle the error
    }
}

export { sendEmail };
