import { useState, useCallback, useEffect } from 'react'
import './bottom-banner.scss';
import { useNavigate, useParams } from 'react-router-dom';
// eslint-disable-next-line
import maplibregl from "!maplibre-gl"; // ! is important here
import maplibreglWorker from "maplibre-gl/dist/maplibre-gl-csp-worker";

import * as  turf from '@turf/turf';


export default function BottomBanner(props) {

    const { guessedLocation, locations, map, type } = props;

    const [distance, setDistance] = useState(null);
    const [totalDistance, setTotalDistance] = useState(0);

    const [location, setLocation] = useState(0);
    const [marker, setMarker] = useState(null);

    const [final, setFinal] = useState(false);

    const navigate = useNavigate();

    const getDistance = (distance) => {
        if (distance >= 1) {
            return Math.round(distance) + " Kilometer";
        } else {
            return Math.round(distance * 1000) + " Meter";
        }
    }

    const revealLocation = () => {
        const from = turf.point([guessedLocation.lng, guessedLocation.lat]);
        const to = turf.point([locations[location].coordinates.lng, locations[location].coordinates.lat]);
        const options = { units: 'kilometers' };


        const distance = turf.distance(from, to, options);
        setDistance(distance);
        setTotalDistance(totalDistance + distance);

        let tmpResult = JSON.parse(localStorage.getItem('result'));
        if (tmpResult === null) {
            tmpResult = [];
        }
        console.log(tmpResult);
        tmpResult.push({ 'actualLocation': locations[location], 'guessedLocation': guessedLocation });

        localStorage.setItem('result', JSON.stringify(tmpResult));

        const newMarker = new maplibregl.Marker({
            color: '#642832'
        }).setLngLat([locations[location].coordinates.lng, locations[location].coordinates.lat]).addTo(map.current);

        setMarker(newMarker);

        const topPadding = 1; // Padding for the top side
        const bottomPadding = 2; // Padding for the bottom side

        const bounds = new maplibregl.LngLatBounds()
            .extend([guessedLocation.lng, guessedLocation.lat])
            .extend([locations[location].coordinates.lng, locations[location].coordinates.lat]);

        // Calculate the height of the bounds
        const height = bounds.getNorth() - bounds.getSouth();

        // Calculate the new bounds with individual padding for top and bottom
        const newBounds = new maplibregl.LngLatBounds()
            .extend([bounds.getWest(), bounds.getSouth() - (bottomPadding * height)])
            .extend([bounds.getEast(), bounds.getNorth() + (topPadding * height)]);


        map.current.fitBounds(newBounds, { padding: 10 });

    }

    const nextImage = (marker) => {
        setDistance(null);
        if (location + 1 < locations.length) {

            console.log(location);
            console.log(locations.length);
            setLocation(location + 1);

            if (marker) {
                marker.remove();
            }
            console.log(location);

            marker.setLngLat([locations[1].coordinates.lng, locations[1].coordinates.lat]);
        } else {
            setFinal(true);
        }

    }

    useEffect(() => {
        console.log(type);
        if (type !== undefined) {
            localStorage.setItem('type', type);
        }
    }, [])

    return (<div id="pop-up">

        <div className="pop-up-content">
            <div className="pop-up__image">
                <img alt="" src={locations[location].image}></img>
            </div>
            <div className="pop-up__text">
                {final === false ?
                    <div>
                        <h1>Errate die Location!</h1>

                        {distance === null ?
                            <div>
                                <div>Klicke auf der Karte an die Stelle, an der das Bild aufgenommen wurde.</div>
                                <div className="button" onClick={revealLocation}>auflösen</div>
                            </div>

                            :
                            <div>
                                <div>Distanz: {getDistance(distance)}</div>
                                <div>Gesamte Distanz: {getDistance(totalDistance)}</div>

                                <div className="button" onClick={() => nextImage(marker)}>nächstes Bild</div>
                            </div>

                        }
                    </div>
                    :
                    <div>
                        <h1>Wettbewerb</h1>
                        <div>
                            <div>Möchtest du am Wettbewerb teilnehmen? Hier kannst du deine Kontaktdaten eingeben und du nimmst an der Vorlesung teil.</div>
                            <div className="button" onClick={navigate('/signup')}>zum Wettbewerb</div>
                        </div>
                    </div>

                }


            </div>
        </div>
    </div>);
}